/* tslint:disable */
import { IImageSettings } from '@msdyn365-commerce/core';
import { Module, Node } from '@msdyn365-commerce-modules/utilities';
import { IMediaGalleryThumbnailItemViewProps, IMediaGalleryThumbnailsViewProps, IMediaGalleryViewProps } from '@msdyn365-commerce-modules/media-gallery/src/modules/media-gallery/../..';
import * as React from 'react';

const MediaGalleryView: React.FC<IMediaGalleryViewProps> = props => {
    /*RA - 10152020 - theme settings doesn't work, so we have to manually do this*/
    const {CarouselProps, Thumbnails, MediaGallery, Modal} = props;
    const fhiDefaultGalleryImageSettings: IImageSettings = {
        viewports: {
            xs: { q: 'w=0&h=320&q=80&m=6&f=jpg', w: 0, h: 0 },
            sm: { q: 'w=0&h=420&q=80&m=6&f=jpg', w: 0, h: 0 },
            md: { q: 'w=0&h=520&q=80&m=6&f=jpg', w: 0, h: 0 },
            lg: { q: 'w=0&h=620&q=80&m=6&f=jpg', w: 0, h: 0 },
            xl: { q: 'w=0&h=720&q=80&m=6&f=jpg', w: 0, h: 0 }
        },
        lazyload: true
    };   
    const moduleProperties:any = MediaGallery.moduleProps;
    moduleProperties.config.galleryImageSettings = fhiDefaultGalleryImageSettings;
    /*RA - 10152020 - End*/
    return (
        <Module {...MediaGallery}>
            <Node {...CarouselProps} />
            {Modal}
            {_renderThumbnails(Thumbnails)}
        </Module>
    );
};

const _renderThumbnails = (thumbnails: IMediaGalleryThumbnailsViewProps): JSX.Element => {
    const { ThumbnailsContainerProps, SingleSlideCarouselComponentProps, items } = thumbnails;

    return (
        <Node {...ThumbnailsContainerProps}>
            <Node {...SingleSlideCarouselComponentProps}>
                {items && items.map(_renderThumbnailItem)}
            </Node>
        </Node>
    );
};

const _renderThumbnailItem = (thumbnail: IMediaGalleryThumbnailItemViewProps): JSX.Element => {
    const { ThumbnailItemContainerProps, Picture } = thumbnail;

    return (
        <Node {...ThumbnailItemContainerProps}>
            {Picture}
        </Node>
    );
};

export default MediaGalleryView;